import { Link } from 'react-router-dom';
import { Carousel, Image, Col, Row } from 'react-bootstrap';
// import Loader from './Loader';
import Message from './Message';
import { useGetTopProductsQuery } from '../slices/productsApiSlice';
import assets from '../config.js';

const ProductCarousel = () => {
	const { data: products, isLoading, error } = useGetTopProductsQuery();

	return isLoading ? null : error ? (
		error?.data?.message === 'TypeError: Failed to fetch' ||
		error.error === 'TypeError: Failed to fetch' ? (
			<Message variant='danger'>
				You are currently offline. Product details are not available. Please
				connect to the internet and try again.
			</Message>
		) : (
			<Message variant='danger'>
				{error?.data?.message ||
					error.error ||
					'An error occurred, please try again'}
			</Message>
		)
	) : (
		<Carousel pause='hover' className='bg-primary mb-4 custom-carousel'>
			<Carousel.Item
				className='custom-carousel-item'
				style={{ backgroundColor: '#fff' }}>
				<Image
					src={assets.cabsonsLogoDark}
					alt='logo'
					fluid
					className='first-item-image'
				/>
				<Carousel.Caption className='carousel-caption'>
					<h2 className='text-white text-right'>
						Welcome to Cabsons Nutra Biohealth
					</h2>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item
				className='custom-carousel-item'
				style={{ backgroundColor: '#fff' }}>
				<Image
					src={assets.fruit}
					alt='logo'
					fluid
					className='second-item-image'
				/>
				<Carousel.Caption className='carousel-caption'>
					<h2 className='text-white text-right'>
						All products made from organic ingredients
					</h2>
				</Carousel.Caption>
			</Carousel.Item>
			{products.map((product) => (
				<Carousel.Item key={product._id} className='custom-carousel-item'>
					<Row>
						<Col>
							<Link to={`/product/${product._id}`}>
								<Image
									src={product.image}
									alt={product.name}
									fluid
									className='img-fluid'
								/>
							</Link>
						</Col>
						<Col className='d-none d-lg-block'>
							<p
								style={{
									color: 'white',
									fontSize: '20px',
									lineHeight: '1.5',
									padding: '50px 20px',
									borderRadius: '10px',
									textAlign: 'center',
								}}
								className='text-center'>
								{product.description}
							</p>
						</Col>
						<Carousel.Caption className='carousel-caption'>
							<h2 className='text-white text-right'>
								{product.name} (${product.price})
							</h2>
						</Carousel.Caption>
					</Row>
				</Carousel.Item>
			))}
		</Carousel>
	);
};

export default ProductCarousel;
