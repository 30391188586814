import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
	Form,
	Row,
	Col,
	Image,
	ListGroup,
	Card,
	Button,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	useGetProductDetailsQuery,
	useCreateReviewMutation,
} from '../slices/productsApiSlice';
import { useHasPurchased } from '../hooks/useHasPurchased';
import { addToCart } from '../slices/cartSlice';
import Rating from '../components/Rating';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Meta from '../components/Meta';
import { Helmet } from 'react-helmet-async';
import { split } from 'lodash';

// Product screen component
const ProductScreen = () => {
	// Get the product ID from the URL
	const { id: productId } = useParams();

	// Get user info from the Redux store
	const { userInfo } = useSelector((state) => state.auth);

	// Check if the user has purchased the product
	const hasPurchased = useHasPurchased(productId, userInfo);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Function to navigate to a different page
	const navigate = useNavigate();
	// Dispatch function from the Redux store
	const dispatch = useDispatch();

	// Local state for the quantity
	const [qty, setQty] = useState(1);
	const [rating, setRating] = useState(0);
	const [comment, setComment] = useState('');

	// Get product details from the API
	const {
		data: product,
		error,
		isLoading,
		refetch,
	} = useGetProductDetailsQuery(productId);

	// Function to handle the add to cart button click
	const addToCartHandler = () => {
		// Dispatch the addToCart action with the product and quantity and navigate to the cart page
		dispatch(addToCart({ ...product, qty }));
		navigate('/cart');
		// Track the event
		window.gtag('event', 'add_to_cart', {
			event_category: 'User Interaction',
			event_label: 'Added product to cart',
		});
	};

	// Create a review mutation
	const [createReview, { isLoading: loadingProductReview }] =
		useCreateReviewMutation();

	// Function to handle the form submit for creating a review
	const submitHandler = async (e) => {
		e.preventDefault();

		try {
			await createReview({
				productId,
				rating,
				comment,
			}).unwrap();
			refetch();
			toast.success('Review created successfully');
			// Track the event
			window.gtag('event', 'submit_review', {
				event_category: 'User Interaction',
				event_label: 'Submitted review',
			});
		} catch (err) {
			if (
				err?.data?.message === 'TypeError: Failed to fetch' ||
				err.error === 'TypeError: Failed to fetch'
			) {
				toast.error(
					'You are currently offline. Please connect to the internet and try again.',
				);
			} else if (err?.data?.message) {
				// Display the error message from the backend
				toast.error(err?.data?.message);
			} else if (err.status === 400) {
				toast.error('Bad request. Please check your input and try again.');
			} else if (err.status === 500) {
				toast.error('Internal server error. Please try again later.');
			} else {
				toast.error(
					err?.data?.message ||
						err.error ||
						'An unexpected error occurred. Please try again.',
				);
			}
		}
	};

	// useEffect(() => {
	// 	if (!navigator.onLine && (error?.error || product?.error)) {
	// 		window.location.href = '/offline.html';
	// 	}
	// }, [error, product]);

	return (
		<>
			{/* Back button */}
			<Link className='btn btn-light my-3' to='/'>
				Go Back
			</Link>

			{/* Loading and error messages */}
			{isLoading ? (
				<Loader />
			) : error ? (
				error?.data?.message === 'TypeError: Failed to fetch' ||
				error.error === 'TypeError: Failed to fetch' ? (
					<Message variant='danger'>
						You are currently offline. Product details are not available. Please
						connect to the internet and try again.
					</Message>
				) : (
					<Message variant='danger'>
						{error?.data?.message ||
							error.error ||
							'An error occurred, please try again'}
					</Message>
				)
			) : (
				<>
					{/* Product details */}
					<Meta
						title={product.name}
						description={product.description}
						keywords={`${product.category}, ${split(product.name, ' ').join(
							', ',
						)}, ${split(product.description, ' ').join(
							', ',
						)}, Cabsons Nutra Biohealth, Mens Health, Womens Health, Respiratory System, Metabolic System, Nervous System, Skeletal System, Endocrine System, Immune System, Cardiovascular System, Anti-Aging, Health Products, Supplements, Wellness, Online Shopping, Quality Products, Health and Wellness, Dietary Supplements, Herbal Supplements, Fitness Supplements, Natural Remedies, Health Solutions, Personal Care, Healthy Lifestyle, Nutritional Supplements, Vitamins, Minerals, Health Care, Body Care, Natural Health Products, Health Shop, Health Store, Health and Beauty, Health Brands, Health Retail, Health Market `}
						image={`${window.location.origin}/images/cabsons-512.png`}
					/>
					<Helmet>
						<script type='application/ld+json'>
							{JSON.stringify({
								'@context': 'https://schema.org/',
								'@type': 'Product',
								name: product.name,
								image: [window.location.origin + product.image],
								description: product.description,
								offers: {
									'@type': 'Offer',
									priceCurrency: 'USD',
									price: product.price,
								},
								aggregateRating: {
									'@type': 'AggregateRating',
									ratingValue: product.rating,
									reviewCount: product.numReviews,
								},
							})}
						</script>
					</Helmet>
					<Row>
						<Col md={5}>
							<Image src={product.image} alt={product.name} fluid />
						</Col>
						<Col md={4}>
							<ListGroup variant='flush'>
								<ListGroup.Item>
									<h3>{product.name}</h3>
								</ListGroup.Item>
								<ListGroup.Item>
									<Rating
										value={product.rating}
										text={`${product.numReviews} reviews`}
									/>
								</ListGroup.Item>
								<ListGroup.Item>Price: ${product.price}</ListGroup.Item>
								<ListGroup.Item>
									Description: {product.description}
								</ListGroup.Item>
							</ListGroup>
						</Col>
						<Col md={3}>
							<Card>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<Row>
											<Col>Price:</Col>
											<Col>
												<strong>${product.price}</strong>
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<Row>
											<Col>Status:</Col>
											<Col>
												{product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}
											</Col>
										</Row>
									</ListGroup.Item>

									{product.countInStock > 0 && (
										<ListGroup.Item>
											<Row>
												<Col>Qty</Col>
												<Col>
													<Form.Control
														as='select'
														value={qty}
														onChange={(e) => setQty(Number(e.target.value))}>
														{[...Array(product.countInStock).keys()].map(
															(x) => (
																<option key={x + 1} value={x + 1}>
																	{x + 1}
																</option>
															),
														)}
													</Form.Control>
												</Col>
											</Row>
										</ListGroup.Item>
									)}

									<ListGroup.Item>
										<Button
											className='btn-block'
											type='button'
											disabled={product.countInStock === 0}
											onClick={addToCartHandler}>
											Add To Cart
										</Button>
									</ListGroup.Item>
								</ListGroup>
							</Card>
						</Col>
					</Row>

					{/* Product reviews */}
					<Row>
						<Col md={6}>
							<h2>Reviews</h2>
							{product.reviews && product.reviews.length === 0 && (
								<Message>No Reviews</Message>
							)}
							<ListGroup variant='flush'>
								{product.reviews &&
									product.reviews.map((review) => (
										<ListGroup.Item key={review._id}>
											<strong>{review.name}</strong>
											<Rating value={review.rating} />
											<p>{review.createdAt.substring(0, 10)}</p>
											<p>{review.comment}</p>
										</ListGroup.Item>
									))}
								<ListGroup.Item>
									<h2>Write a Customer Review</h2>

									{loadingProductReview && <Loader />}

									{userInfo ? (
										hasPurchased ? (
											<Form onSubmit={submitHandler}>
												<Form.Group className='my-2' controlId='rating'>
													<Form.Label>Rating</Form.Label>
													<Form.Control
														as='select'
														required
														value={rating}
														onChange={(e) => setRating(e.target.value)}>
														<option value=''>Select...</option>
														<option value='1'>1 - Poor</option>
														<option value='2'>2 - Fair</option>
														<option value='3'>3 - Good</option>
														<option value='4'>4 - Very Good</option>
														<option value='5'>5 - Excellent</option>
													</Form.Control>
												</Form.Group>
												<Form.Group className='my-2' controlId='comment'>
													<Form.Label>Comment</Form.Label>
													<Form.Control
														as='textarea'
														row='3'
														required
														value={comment}
														onChange={(e) =>
															setComment(e.target.value)
														}></Form.Control>
												</Form.Group>
												<Button
													disabled={loadingProductReview}
													type='submit'
													variant='primary'>
													Submit
												</Button>
											</Form>
										) : (
											<Message>
												You must purchase this product before you can leave a
												review.
											</Message>
										)
									) : (
										<Message>
											Please <Link to='/login'>sign in</Link> to write a review
										</Message>
									)}
								</ListGroup.Item>
							</ListGroup>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default ProductScreen;
