import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaChevronLeft } from 'react-icons/fa';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { PaystackButton } from 'react-paystack';
import Meta from '../components/Meta';
import {
	useGetOrderDetailsQuery,
	usePayOrderMutation,
	useDeliverOrderMutation,
} from '../slices/ordersApiSlice';

// Order screen component
const OrderScreen = () => {
	// Get the order ID from the URL
	const { id: orderId } = useParams();

	// Get the user info from the Redux store
	const { userInfo } = useSelector((state) => state.auth);

	// Get Order Details
	const {
		data: order,
		refetch,
		isLoading,
		error,
	} = useGetOrderDetailsQuery(orderId);

	// Get the navigate function
	const navigate = useNavigate();

	// Function to handle the 'Go Back' button click
	const handleGoBack = () => {
		navigate(-1);
	};

	// Pay Order
	const [payOrder, { isLoading: isPaying }] = usePayOrderMutation();

	// Deliver Order
	const [deliverOrder, { isLoading: loadingDeliver }] =
		useDeliverOrderMutation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Refetch the order details after payment
	useEffect(() => {
		if (order && order.isPaid) {
			refetch();
		}
	}, [order, refetch]);

	const [convertedAmount, setConvertedAmount] = useState(null);
	// const [isConverting, setIsConverting] = useState(false);

	// Convert the order total price to GHS
	useEffect(() => {
		const fetchConvertedAmount = async () => {
			// setIsConverting(true);
			try {
				const response = await fetch(
					`/api/convertCurrency/${order?.totalPrice}/GHS`,
				);
				if (!response.ok) {
					const errorData = await response.json();
					throw new Error(errorData.error);
				}
				const data = await response.json();
				const formattedAmount = parseFloat(data.convertedAmount).toFixed(2);
				setConvertedAmount(formattedAmount);
			} catch (error) {
				console.error('A problem occurred with fetch operation: ', error);
				if (
					error?.message === 'Failed to fetch' ||
					error?.message === 'TypeError: Failed to fetch'
				) {
					toast.error(
						'You are currently offline. Please connect to the internet and try again.',
					);
				} else if (error?.message) {
					// Display the error message from the backend
					toast.error(error?.message);
				} else if (error?.message?.includes('400')) {
					toast.error('Bad request. Please check your input and try again.');
				} else if (error?.message?.includes('500')) {
					toast.error('Internal server error. Please try again later.');
				} else {
					toast.error(
						error?.message || 'An unexpected error occurred. Please try again.',
					);
				}
			} finally {
				// setIsConverting(false);
			}
		};

		if (order && order.totalPrice !== null) {
			fetchConvertedAmount();
		}
	}, [order]);

	// Paystack configuration
	const config = convertedAmount && {
		reference: `${new Date().getTime()}-${Math.floor(Math.random() * 1000000)}`,
		email: order?.user?.email,
		amount: Math.floor(convertedAmount * 100), // Convert to Ghana Pesewas
		publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
		text: 'Pay Now',
		currency: 'GHS',
		channels: ['card', 'bank', 'ussd', 'qr', 'mobile_money', 'bank_transfer'],
		metadata: {
			custom_fields: [
				{
					display_name: 'User Name',
					variable_name: 'user_name',
					value: order?.user?.name,
				},
			],
		},
	};

	// Handle successful payment
	const handlePaystackSuccessAction = (reference) => {
		// Check if payment was successful and reference is defined
		if (reference && reference.status === 'success') {
			// Call the payOrder API endpoint and refetch the order details
			payOrder({ orderId, paymentResult: reference })
				.unwrap()
				.then((payload) => {
					// Handle successful payment here
					toast.success('Payment successful!');
					// Track the event
					window.gtag('event', 'paid_order', {
						event_category: 'User Interaction',
						event_label: 'Paid for an order',
					});

					// Refetch the order details
					refetch();
				})
				.catch((err) => {
					if (
						err?.data?.message === 'TypeError: Failed to fetch' ||
						err.error === 'TypeError: Failed to fetch'
					) {
						toast.error(
							'You are currently offline. Please connect to the internet and try again.',
						);
					} else if (err?.data?.message) {
						// Display the error message from the backend
						toast.error(err?.data?.message);
					} else if (err.status === 400) {
						toast.error('Bad request. Please check your input and try again.');
					} else if (err.status === 500) {
						toast.error('Internal server error. Please try again later.');
					} else {
						toast.error(
							err?.data?.message ||
								err.error ||
								'An unexpected error occurred. Please try again.',
						);
					}
				});
		} else {
			toast.error('Payment failed. Reference is not defined.');
		}
	};

	// Handle closing of Paystack dialog
	const handlePaystackCloseAction = () => {
		console.log('Paystack dialog closed');
	};

	// Paystack component props
	const componentProps = convertedAmount && {
		...config,
		text: 'Pay Now',
		onSuccess: handlePaystackSuccessAction, // This is a function that handles successful payments
		onClose: handlePaystackCloseAction, // This is a function that handles the closing of the Paystack dialog
	};

	// Delivery Handler
	const deliverOrderHandler = async () => {
		if (
			window.confirm('Are you sure you want to mark this order as delivered?')
		) {
			try {
				await deliverOrder(orderId);
				refetch();
				toast.success('Order has been delivered');
				// Track the event
				window.gtag('event', 'delivered_order', {
					event_category: 'Admin Interaction',
					event_label: 'Marked an order as delivered',
				});
			} catch (err) {
				if (
					err?.data?.message === 'TypeError: Failed to fetch' ||
					err.error === 'TypeError: Failed to fetch'
				) {
					toast.error(
						'You are currently offline. Please connect to the internet and try again.',
					);
				} else if (err?.data?.message) {
					// Display the error message from the backend
					toast.error(err?.data?.message);
				} else if (err.status === 400) {
					toast.error('Bad request. Please check your input and try again.');
				} else if (err.status === 500) {
					toast.error('Internal server error. Please try again later.');
				} else {
					toast.error(
						err?.data?.message ||
							err.error ||
							'An unexpected error occurred. Please try again.',
					);
				}
			}
		}
	};

	return (
		<>
			<Link className='btn btn-light my-3 ms-3' to='/'>
				Home
			</Link>
			{isLoading || isPaying ? (
				<Loader />
			) : error ? (
				error?.data?.message === 'TypeError: Failed to fetch' ||
				error.error === 'TypeError: Failed to fetch' ? (
					<Message variant='danger'>
						You are currently offline. Order details not available. Please
						connect to the internet and try again.
					</Message>
				) : (
					<Message variant='danger'>
						{error?.data?.message ||
							error.error ||
							'An error occurred, please try again'}
					</Message>
				)
			) : (
				<>
					<Meta
						title={`Cabsons Nutra Order - ${order._id}`}
						description='Details of your order including shipping address, payment method, order items, and order summary'
						keywords='order details, shipping address, payment method, order items, order summary'
						image={`${window.location.origin}/images/cabsons-512.png`}
					/>
					<h1>Order {order._id}</h1>
					<Row>
						<Col md={8}>
							<ListGroup variant='flush'>
								<ListGroup.Item>
									<h2>Shipping</h2>
									<p>
										<strong>Name: </strong> {order?.user.name}
									</p>
									<p>
										<strong>Email: </strong>{' '}
										<a href={`mailto:${order?.user?.email}`}>
											{order?.user?.email}
										</a>
									</p>
									<p>
										<strong>Address: </strong>
										{order?.shippingAddress?.address},{' '}
										{order?.shippingAddress?.city}{' '}
										{order?.shippingAddress?.postalCode},{' '}
										{order?.shippingAddress?.country}
									</p>
									<p>
										<strong>Phone Number: </strong>
										{order.shippingAddress.phoneNumber}
									</p>
									{order.isDelivered ? (
										<Message variant='success'>
											Delivered on {order.deliveredAt}
										</Message>
									) : (
										<Message variant='danger'>Not Delivered</Message>
									)}
								</ListGroup.Item>

								<ListGroup.Item>
									<h2>Payment Method</h2>
									<p>
										<strong>Method: </strong>
										{order?.paymentMethod}
									</p>
									{order.isPaid ? (
										<Message variant='success'>Paid on {order.paidAt}</Message>
									) : (
										<Message variant='danger'>Not Paid</Message>
									)}
								</ListGroup.Item>

								<ListGroup.Item>
									<h2>Order Items</h2>
									{order?.orderItems.length === 0 ? (
										<Message>Order is empty</Message>
									) : (
										<ListGroup variant='flush'>
											{order?.orderItems.map((item, index) => (
												<ListGroup.Item key={index}>
													<Row>
														<Col md={1}>
															<Image
																src={item.image}
																alt={item.name}
																fluid
																rounded
															/>
														</Col>
														<Col>
															<Link to={`/product/${item.product}`}>
																{item.name}
															</Link>
														</Col>
														<Col md={4}>
															{item.qty} x ${item.price} = $
															{item.qty * item.price}
														</Col>
													</Row>
												</ListGroup.Item>
											))}
										</ListGroup>
									)}
								</ListGroup.Item>
							</ListGroup>
						</Col>
						<Col md={4}>
							<Card>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<h2>Order Summary</h2>
									</ListGroup.Item>
									<ListGroup.Item>
										<Row>
											<Col>Item(s) Price</Col>
											<Col>${order?.itemsPrice}</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item>
										<Row>
											<Col>Shipping</Col>
											<Col>${order?.shippingPrice}</Col>
										</Row>
									</ListGroup.Item>
									{/* <ListGroup.Item>
								<Row>
									<Col>Tax</Col>
									<Col>${order.taxPrice}</Col>
								</Row>
							</ListGroup.Item> */}
									<ListGroup.Item>
										<Row>
											<Col>Total (USD)</Col>
											<Col>${order?.totalPrice}</Col>
										</Row>
									</ListGroup.Item>
									{/* <ListGroup.Item>
										<Row>
											<Col>Total (GHS)</Col>
											<Col>
												{isConverting ? <Loader /> : `₵${convertedAmount}`}
											</Col>
										</Row>
									</ListGroup.Item> */}
									{/* PAYMENT */}
									{!order.isPaid && (
										<ListGroup.Item>
											<PaystackButton
												className='btn btn-primary'
												{...componentProps}
											/>
										</ListGroup.Item>
									)}
									{/* {MARK AS DELIVERED PLACEHOLDER} */}
									{loadingDeliver && <Loader />}

									{userInfo &&
										userInfo.isAdmin &&
										order.isPaid &&
										!order.isDelivered && (
											<ListGroup.Item>
												<Button
													type='button'
													className='btn btn-block'
													onClick={deliverOrderHandler}>
													Mark As Delivered
												</Button>
												<Button
													className='btn btn-primary my-3 ms-3'
													onClick={handleGoBack}>
													<FaChevronLeft />
													Previous Page
												</Button>
											</ListGroup.Item>
										)}
								</ListGroup>
							</Card>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default OrderScreen;
