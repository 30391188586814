import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	Row,
	Col,
	ListGroup,
	Image,
	Form,
	Button,
	Card,
	ListGroupItem,
} from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import Message from '../components/Message';
import Meta from '../components/Meta';
import { addToCart, removeFromCart } from '../slices/cartSlice';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';

// Cart screen
const CartScreen = () => {
	// Navigation hook
	const navigate = useNavigate();
	// Redux store dispatch hook
	const dispatch = useDispatch();

	// Get cart state from the store
	const cart = useSelector((state) => state.cart);
	// Destructure cart state
	const { cartItems } = cart;

	// add to cart handler
	const addToCartHandler = async (product, qty) => {
		// Dispatch add to cart action
		dispatch(addToCart({ ...product, qty }));
		// Track the event
		window.gtag('event', 'add_to_cart', {
			event_category: 'Product Interaction',
			event_label: `Added ${product.name} to cart`,
		});
	};

	// Function to handle removing items from the cart
	const removeFromCartHandler = async (id) => {
		// Display confirmation dialog
		if (
			window.confirm('Are you sure you want to remove this item from the cart?')
		) {
			try {
				// If the user clicked OK, dispatch remove from cart action
				dispatch(removeFromCart(id));
				// Track the event
				// window.gtag('event', 'remove_from_cart', {
				// 	event_category: 'User Interaction',
				// 	event_label: 'Removed item from cart',
				// });
			} catch (error) {
				if (
					error?.data?.message === 'TypeError: Failed to fetch' ||
					error.error === 'TypeError: Failed to fetch'
				) {
					toast.error(
						'You are currently offline. Please connect to the internet and try again.',
					);
				} else if (error?.data?.message) {
					// Display the error message from the backend
					toast.error(error?.data?.message);
				} else if (error.status === 400) {
					toast.error('Bad request. Please check your input and try again.');
				} else if (error.status === 500) {
					toast.error('Internal server error. Please try again later.');
				} else {
					toast.error(
						error.data?.message ||
							error.message ||
							'An unexpected error occurred. Please try again.',
					);
				}
			}
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// checkout handler
	const checkoutHandler = () => {
		// Redirect to login page
		navigate('/login?redirect=/shipping');
		// Track the event
		window.gtag('event', 'proceed_to_checkout', {
			event_category: 'User Interaction',
			event_label: 'Proceeded to checkout',
		});
	};

	return (
		<div>
			<Meta
				title='Your Shopping Cart - Cabsons Nutra Biohealth'
				description='View the items in your shopping cart, update quantities, or proceed to checkout'
				keywords='shopping cart, checkout'
			/>
			<Row>
				<Col md={8}>
					<h1 style={{ marginBottom: '20px' }}>Shopping Card</h1>
					{cartItems.length === 0 ? (
						<Message>
							Your cart is empty{' '}
							<Link className='btn btn-light my-3' to='/'>
								Home
							</Link>
						</Message>
					) : (
						<ListGroup variant='flush'>
							{cartItems.map((item) => (
								<ListGroup.Item key={item._id}>
									<Row>
										<Col md={2}>
											<Image src={item.image} alt={item.name} fluid rounded />
										</Col>
										<Col md={3}>
											<Link to={`/product/${item._id}`}>
												<p className='cart-product-title mt-2'>{item.name}</p>
											</Link>
										</Col>
										<Col md={2}>
											<p className='m-2'>${item.price}</p>
										</Col>
										<Col md={2}>
											<Form.Control
												as='select'
												value={item.qty}
												onChange={(e) =>
													addToCartHandler(item, Number(e.target.value))
												}
												className='m-1'>
												{[...Array(item.countInStock).keys()].map((x) => (
													<option key={x + 1} value={x + 1}>
														{x + 1}
													</option>
												))}
											</Form.Control>
										</Col>
										<Col md={2}>
											<Button
												type='button'
												variant='light'
												className='m-1'
												onClick={() => removeFromCartHandler(item._id)}>
												<FaTrash style={{ color: 'red' }} className='me-1' />
												Delete
											</Button>
										</Col>
									</Row>
								</ListGroup.Item>
							))}
						</ListGroup>
					)}
				</Col>
				<Col md={4}>
					<Card>
						<ListGroup variant='flush'>
							<ListGroupItem>
								<h2>
									Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}
									) Items
								</h2>
								$
								{cartItems
									.reduce((acc, item) => acc + item.qty * item.price, 0)
									.toFixed(2)}
							</ListGroupItem>
							<ListGroupItem>
								<Row>
									<Col>
										<Button
											type='button'
											className='btn-block mt-1 mb-1'
											disabled={cartItems.length === 0}
											onClick={checkoutHandler}>
											Proceed To Checkout
										</Button>
									</Col>
									<Col>
										<Link className='btn btn-primary mt-1 mb-1' to='/'>
											Continue Shopping
										</Link>
									</Col>
								</Row>
							</ListGroupItem>
						</ListGroup>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default CartScreen;
